import {
  ChangeDetectorRef,
  Component, computed, DoCheck, ElementRef, EventEmitter,
  Inject,
  inject, Injector,
  Input,
  LOCALE_ID, OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID, QueryList, runInInjectionContext,
  signal, TemplateRef,
  ViewChild, ViewChildren
} from '@angular/core';
import {
  injectStripe,
  StripeElementsDirective,
  StripePaymentElementComponent, StripeServiceInterface
} from 'ngx-stripe';
import {
  StripeElementLocale,
  StripeElementsOptions,
  StripePaymentElementOptions
} from '@stripe/stripe-js';
import { environment } from "../../../../../environments/environment";
import { Event, InitializedPayment, MerchantMembershipType, PaymentMethod } from "../../../../shared/graphql/generated/graphql";
import { debounceTime, firstValueFrom, lastValueFrom, Observable, Subscription } from "rxjs";
import { isPlatformBrowser } from "@angular/common";


export interface CheckoutParams {
  [key: string]: any;
  returnPath: string;
  price: number;
  paymentMethods: PaymentMethod[];
  stripePublishableKey: string;
}

export enum PaymentStatus {
  initial,
  inProgress,
  succeeded,
  failed
}

@Component({
  selector: 'cs-stripe-checkout',
  templateUrl: './stripe-checkout.component.html',
  styleUrl: './stripe-checkout.component.scss'
})
export class StripeCheckoutComponent implements OnInit, OnDestroy, DoCheck {

  @Input({ required: true }) overviewTemplate!: TemplateRef<any>;
  @Input({ required: true }) disclaimerTemplate!: TemplateRef<any>;
  @Input() alternativePaymentOptionTemplate?: TemplateRef<any>;

  @Input({ required: true }) checkoutParams$!: Observable<CheckoutParams>;
  @Input({ required: true }) initializePaymentFn!: (params: CheckoutParams) => Observable<InitializedPayment>;
  @Input() alternativePaymentMethodFn?: (params: CheckoutParams) => Observable<any>;

  @Input() merchantMembershipTypesEligibleForAction?: MerchantMembershipType[];

  @Output() paymentSucceeded = new EventEmitter<string>();
  @Output() paymentStatus = new EventEmitter<PaymentStatus>();


  @ViewChild(StripePaymentElementComponent) paymentElement?: StripePaymentElementComponent;

  isMobile = false;

  error?: string;

  alternativePaymentLoading = signal(false);

  alternativePaymentButtonDisabled = computed<boolean>(() => {
    return this.alternativePaymentLoading() || this.paying();
  })
  checkoutButtonDisabled = computed<boolean>(() => {
    return this.paying() || this.alternativePaymentLoading();
  })


  private checkoutParamsSubscription?: Subscription;
  checkoutParams!: CheckoutParams;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, @Inject(LOCALE_ID) public locale: string) {
  }

  elementsOptions?: StripeElementsOptions;

  paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
    }
  };

  paying = signal(false);

  stripe?: StripeServiceInterface;

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {

      const innerWidth = window.screen.width;

      if (innerWidth < 768) {
        this.isMobile = true;
        this.paymentElementOptions.layout = {
          type: 'auto',
          defaultCollapsed: true,
          radios: false,
          spacedAccordionItems: false
        }
      }
    }

    this.subscribeToCheckoutParams();
  }

  injector = inject(Injector);
  changeDetector = inject(ChangeDetectorRef);

  subscribeToCheckoutParams() {
    this.checkoutParamsSubscription = this.checkoutParams$.subscribe({
      next: (params) => {
        this.checkoutParams = params;

        if (!this.stripe) {
          console.debug('injecting stripe with key', this.checkoutParams.stripePublishableKey);
          runInInjectionContext(this.injector, () => {
            this.stripe = injectStripe(this.checkoutParams.stripePublishableKey);
          });
          this.changeDetector.detectChanges();
        }

        this.elementsOptions = {
          locale: this.locale?.toString() as StripeElementLocale,
          mode: 'payment',
          payment_method_types: this.getPaymentMethodTypesFromParams(params),
          amount: params.price * 100,
          currency: 'eur',
          appearance: {
            theme: 'flat',
            variables: {
              colorPrimary: '#007A70',
            }
          },
        };

        this.changeDetector.detectChanges();
      }
    });
  }

  private getPaymentMethodTypesFromParams(params: CheckoutParams): string[] {
    return params.paymentMethods.map((paymentMethod) =>
      this.stripePaymentMethodFromCsPaymentMethod(paymentMethod))
          .filter((paymentMethod) => !!paymentMethod) as string[];
  }

  private stripePaymentMethodFromCsPaymentMethod(paymentMethod: PaymentMethod) {
    switch (paymentMethod) {
      case PaymentMethod.Paypal:
        return 'paypal';
      case PaymentMethod.CreditCard:
        return 'card';
      default:
        return undefined;
    }
  }

  elementReadySubscription?: Subscription;

  ngDoCheck() {
    // console.log('ngDoCheck');
    // console.log('payment el', this.paymentElement);
    if (this.paymentElement && !this.elementReadySubscription) {
     //  this.subscribeElementStatus();
    }
  }

  private subscribeElementStatus() {
    console.log('subscribeElementStatus');
    this.elementReadySubscription = this.paymentElement!.ready.subscribe((ready) => {
      console.log('payment element ready', ready);
    });
  }

  alternativePaymentOptionTemplateContext = { onClick: () => this.handleAlternativePaymentOption(), loading: this.alternativePaymentLoading, disabled: this.alternativePaymentButtonDisabled };

  handleAlternativePaymentOption() {
    if (!this.alternativePaymentMethodFn) {
      throw new Error('No alternative payment method function provided');
      return;
    }
    this.alternativePaymentLoading.set(true);
    this.alternativePaymentMethodFn!(this.checkoutParams).subscribe({
      next: (event) => {
        this.alternativePaymentLoading.set(false);
        this.paymentSucceeded.emit('CASH');
      },
      error: (error) => {
        this.alternativePaymentLoading.set(false);
        this.setPaymentInitializationFailed();
      }
    });
  }

  async pay() {
    this.error = undefined;
    const stripeError = await this.paymentElement!.elements.submit();
    if (stripeError.error && stripeError.error.type == 'validation_error') {
      console.error('Stripe error', stripeError.error);
      return;
    }

    this.paying.set(true);
    this.paymentStatus.emit(PaymentStatus.inProgress);
    this.paymentElement!.update({ readOnly: true });

    this.initializePaymentFn(this.checkoutParams).subscribe({
      next: (initializedPayment) => {
        const clientSecret = initializedPayment.paymentIntentClientSecret;
        this.stripe!
          .confirmPayment({
            clientSecret,
            elements: this.paymentElement!.elements,
            redirect: 'if_required',
            confirmParams: {
              return_url: `${environment.appUrl}${this.checkoutParams.returnPath}`,
            }
          })
          .subscribe(result => {
            this.paying.set(false);
            this.paymentElement!.update({ readOnly: false });
            if (result.error) {
              this.error = result.error.message;
              this.paymentStatus.emit(PaymentStatus.failed);
            } else {
              this.paymentStatus.emit(PaymentStatus.succeeded);
              this.paymentSucceeded.emit(result.paymentIntent.id);
            }
          });
      },
      error: (error) => {
        this.paying.set(false);
        this.paymentStatus.emit(PaymentStatus.failed);
        this.paymentElement!.update({ readOnly: false });
        this.setPaymentInitializationFailed();
      }
    });
  }

  private setPaymentInitializationFailed() {
    this.error = $localize `An error occurred. Please try again.`;
  }

  ngOnDestroy() {
    this.checkoutParamsSubscription?.unsubscribe();
  }
}
